/*!
 *
 *  reel.ts
 *
 */
export default function (cl) {
    const elems = document.querySelectorAll(cl);
    if (elems.length > 0) {
        const setHeight = (elem) => {
            elem.style.height = 'auto';
            const inner = elem.querySelector('.u-reel__inner');
            const height = inner.clientHeight;
            elem.style.height = height + 'px';
        };
        elems.forEach((elem) => {
            setHeight(elem);
        });
        window.addEventListener('resize', () => {
            elems.forEach((elem) => {
                setHeight(elem);
            });
        });
    }
}
