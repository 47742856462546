/*!
 *
 *  search.ts
 *
 */
export default class {
    constructor() {
        this.btn = document.querySelector('#searchButton');
        this.body = document.querySelector('#searchBody');
        this.shouldHide = ['desktop', 'largeDesktop'];
        this.btn.addEventListener('click', this.toggleSearch.bind(this));
        document.addEventListener('click', (e) => {
            if ((this.body.getAttribute('aria-hidden') === 'false'
                && !e.target.closest('.site-search')
                && !e.target.closest(('.site-search-button')))) {
                this.closeMenu();
            }
        });
        window.addEventListener('viewportChanged', this.viewportCheck.bind(this));
        this.viewportCheck();
    }
    toggleSearch(e) {
        e.preventDefault();
        const isSearchOpen = this.body.getAttribute('aria-hidden') === 'false';
        if (isSearchOpen) {
            this.btn.classList.add('-unpressed');
            this.btn.classList.remove('-pressed');
        }
        else {
            this.btn.classList.add('-pressed');
            this.btn.classList.remove('-unpressed');
        }
        this.btn.setAttribute('aria-pressed', `${!isSearchOpen}`);
        this.body.setAttribute('aria-hidden', `${isSearchOpen}`);
    }
    closeMenu() {
        const shouldHide = this.shouldHide.includes(util.viewport);
        if (shouldHide) {
            this.btn.classList.add('-unpressed');
            this.btn.classList.remove('-pressed');
            this.btn.setAttribute('aria-pressed', 'false');
            this.body.setAttribute('aria-hidden', 'true');
        }
    }
    viewportCheck() {
        const shouldHide = this.shouldHide.includes(util.viewport);
        this.body.setAttribute('aria-hidden', `${shouldHide}`);
        this.btn.setAttribute('aria-pressed', `${!shouldHide}`);
    }
}
