/*!
 *
 *  menu.ts
 *
 */
class SiteMenu {
    constructor(options) {
        this.shouldHide = ['phone', 'tablet'];
        this.toggleMenu = () => {
            const isMenuOpen = this.container.getAttribute('aria-hidden') === 'false';
            const html = document.querySelector('html');
            const scrollControll = this.scrollControll.bind(this);
            const eventType = isMenuOpen ? 'removeEventListener' : 'addEventListener';
            const passive = { passive: false };
            const classType = isMenuOpen ? 'remove' : 'add';
            this.btn.setAttribute('aria-pressed', `${!isMenuOpen}`);
            if (isMenuOpen) {
                this.btn.classList.add('-pressed');
                this.btn.classList.remove('-unpressed');
            }
            else {
                this.btn.classList.add('-unpressed');
                this.btn.classList.remove('-pressed');
            }
            this.btn.classList.toggle('-pressed');
            this.btn.classList.toggle('-unpressed');
            this.container.setAttribute('aria-hidden', `${isMenuOpen}`);
            html.style.overflow = isMenuOpen ? 'auto' : 'hidden';
            document[eventType]('touchmove', scrollControll, passive);
            this.header.classList[classType]('-open-menu');
        };
        this.closeMenu = () => {
            this.btn.setAttribute('aria-pressed', 'false');
            this.container.setAttribute('aria-hidden', 'true');
            if (document.querySelector('#menuContainer').getAttribute('aria-hidden') === 'true') {
                document.querySelector('html').style.overflow = 'auto';
                document.removeEventListener('touchmove', this.scrollControll);
                this.header.classList.remove('-open-menu');
            }
            ;
        };
        this.scrollControll = (e) => {
            const isMenuOpen = this.container.getAttribute('aria-hidden') === 'false';
            const inner = e.target.closest('.site-menu__body__inner');
            if (isMenuOpen) {
                if (inner) {
                    if (inner.scrollHeight > inner.clientHeight) {
                        e.stopPropagation();
                    }
                    else {
                        e.preventDefault();
                    }
                }
                else {
                    e.preventDefault();
                }
                ;
            }
            ;
        };
        this.btn = document.querySelector(options.btn);
        this.container = document.querySelector(options.container);
        this.containerName = options.container;
        this.header = document.getElementById('header');
        if (options.shouldHide !== undefined) {
            this.shouldHide = options.shouldHide;
        }
        ;
        this.btn.addEventListener('click', this.toggleMenu);
        document.addEventListener('click', (e) => {
            if ((this.container.getAttribute('aria-hidden') === 'false'
                && !e.target.closest(this.containerName)
                && !e.target.closest(options.btn))) {
                this.closeMenu();
            }
        });
        window.addEventListener('viewportChanged', this.viewportCheck.bind(this));
        this.viewportCheck();
    }
    viewportCheck() {
        const shouldHide = this.shouldHide.includes(util.viewport);
        this.container.setAttribute('style', 'display: none;');
        this.container.setAttribute('aria-hidden', `${shouldHide}`);
        this.btn.setAttribute('aria-pressed', `${!shouldHide}`);
        setTimeout(() => {
            this.container.removeAttribute('style');
        }, 1);
        if (shouldHide) {
            document.querySelector('html').style.overflow = 'auto';
            document.removeEventListener('touchmove', this.scrollControll);
            this.header.classList.remove('-open-menu');
        }
        ;
    }
}
export default SiteMenu;
