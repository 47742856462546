/*!
 *
 * app.js
 *
 */
/*** ./modules **/
import scroll2hash from 'scroll2hash';
import SmoothScroll from 'smooth-scroll';
// import header from 'header';
import search from 'search';
import menu from 'menu';
// import linkCurrent from 'link-current';
import Button from 'button';
import Reel from 'reel';
scroll2hash();
function init() {
    Button('.site-button, .site-sns a, .c-button, .c-icon-button, .prd-tab__button, .ps-search-tab__button');
    SmoothScroll({
        ignore: '.site-global__item.-megamenu > a'
    });
    new search();
    new menu({
        btn: '#menuButton',
        container: '#menuContainer',
    });
    Reel('.u-reel');
    initMegamenu();
    initCorporateTopSlider();
    initSlider();
    initModal();
    initMonookiTopSlider();
    initMonookiLineup();
    initOfficeTopSlider();
    initOfficeLineup();
    initLoopGallery();
    initScrollbar();
    initFaq();
    initAccordion();
    initRelatedProduct();
    initTab();
    initProductSort();
    initProductSearch();
    initTopProductSearch();
    initDownloadTable();
    // new SimpleBar(document.querySelector('.u-scrollbar'));
    // new menu({
    // 	btn: '#searchButton',
    // 	container: '#searchContainer',
    // 	shouldHide: ['phone', 'tablet', 'desktop', 'largeDesktop'],
    // });
    // linkCurrent('.site-global__link');
    // header();
    // initHeroSlider();
    // initSetScrollBar();
    // initZoom();
    setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
    }, 1);
}
;
async function initSlider() {
    const elems = document.querySelectorAll('.c-slider');
    if (elems.length > 0) {
        const { default: Slider } = await import(
        /* webpackChunkName: "slider" */ 'slider');
        Slider(elems);
    }
}
async function initModal() {
    const elems = document.querySelectorAll('[data-fancybox]');
    if (elems.length > 0) {
        const { default: Modal } = await import(
        /* webpackChunkName: "modal" */ 'modal');
        Modal();
    }
}
async function initCorporateTopSlider() {
    const isCorporateTop = document.querySelector('.site-corporate .top-hero') !== null ? true : false;
    if (isCorporateTop) {
        const { default: CorporateTopSlider } = await import(
        /* webpackChunkName: "corporate-top-slider" */ 'corporate-top-slider');
        CorporateTopSlider();
    }
}
;
async function initMonookiTopSlider() {
    const isMonookiTop = document.querySelector('.site-monooki .top-hero') !== null ? true : false;
    if (isMonookiTop) {
        const { default: MonookiTopSlider } = await import(
        /* webpackChunkName: "monooki-top-slider" */ 'monooki-top-slider');
        MonookiTopSlider();
    }
}
;
async function initMonookiLineup() {
    const isMonookiTop = document.querySelector('#monookiLineup') !== null ? true : false;
    if (isMonookiTop) {
        const { default: MonookiLineup } = await import(
        /* webpackChunkName: "monooki-lineup" */ 'monooki-lineup');
        MonookiLineup();
    }
}
;
async function initOfficeTopSlider() {
    const isOfficeTop = document.querySelector('.site-office .top-hero') !== null ? true : false;
    if (isOfficeTop) {
        const { default: OfficeTopSlider } = await import(
        /* webpackChunkName: "office-top-slider" */ 'office-top-slider');
        OfficeTopSlider();
    }
}
;
async function initOfficeLineup() {
    const elem = document.querySelector('#officeLineup');
    if (elem) {
        const { default: OfficeLineup } = await import(
        /* webpackChunkName: "office-lineup" */ 'office-lineup');
        OfficeLineup(elem);
    }
    ;
}
;
async function initLoopGallery() {
    const elems = document.querySelectorAll('.c-loop-gallery');
    if (elems.length > 0) {
        const { default: loogGallery } = await import(
        /* webpackChunkName: "loop-gallery" */ 'loop-gallery');
        loogGallery(elems);
    }
}
async function initScrollbar() {
    const elems = document.querySelectorAll('.u-scrollbar, .c-table:not(.-unscrollable,.-sp-col):not(.prd-table), .wp-block-table:not(.-unscrollable)');
    if (elems.length > 0) {
        const { default: Scrollbar } = await import(
        /* webpackChunkName: "scrollbar" */ 'set-scrollbar');
        Scrollbar(elems);
    }
}
async function initFaq() {
    const elems = document.querySelectorAll('.c-faq');
    if (elems.length > 0) {
        const { default: Accordion } = await import(
        /* webpackChunkName: "accordion" */ 'accordion');
        Accordion('.c-faq', {
            containerSelector: '.c-faq__item',
            headSelector: '.c-faq__head',
            bodySelector: '.c-faq__body',
        });
    }
}
async function initAccordion() {
    const elems = document.querySelectorAll('.c-accordion');
    if (elems.length > 0) {
        const { default: Accordion } = await import(
        /* webpackChunkName: "accordion" */ 'accordion');
        Accordion('.c-accordion', {
            containerSelector: '.c-accordion__item',
            headSelector: '.c-accordion__head',
            bodySelector: '.c-accordion__body',
        });
    }
}
async function initRelatedProduct() {
    const elems = document.querySelectorAll('.prd-table:not(.prd-sort-result .prd-table) table');
    if (elems.length > 0) {
        const { default: RelatedProduct } = await import(
        /* webpackChunkName: "related-product" */ 'related-product');
        RelatedProduct(elems);
    }
}
async function initTab() {
    const elem = document.querySelector('.prd-tab, .ps-search:not(#product-search)');
    if (elem) {
        const { default: Tab } = await import(
        /* webpackChunkName: "tab" */ 'tab');
        Tab('.prd-tab, .ps-search');
    }
    ;
}
async function initProductSort() {
    const elem = document.querySelector('#productSort');
    if (elem) {
        const { default: ProductSort } = await import(
        /* webpackChunkName: "product-sort" */ 'product-sort');
        ProductSort();
    }
    ;
}
async function initMegamenu() {
    const elem = document.querySelector('.site-global__item.-megamenu');
    if (elem) {
        const { default: Megamenu } = await import(
        /* webpackChunkName: "megamenu" */ 'megamenu');
        Megamenu();
    }
    ;
}
async function initTopProductSearch() {
    const elem = document.querySelector('#top-product-search');
    if (elem) {
        const { default: TopProductSearch } = await import(
        /* webpackChunkName: "top-product-search" */ 'top-product-search');
        TopProductSearch();
    }
    ;
}
async function initProductSearch() {
    const elem = document.querySelector('#product-search');
    if (elem) {
        const { default: ProductSearch } = await import(
        /* webpackChunkName: "product-search" */ 'product-search');
        ProductSearch();
    }
    ;
}
async function initDownloadTable() {
    const elems = document.querySelectorAll('.prd-download');
    if (elems.length > 0) {
        const { default: DownloadTable } = await import(
        /* webpackChunkName: "download-table" */ 'download-table');
        DownloadTable(elems);
    }
    ;
}
document.readyState !== "loading" ? init() : document.addEventListener("DOMContentLoaded", init);
