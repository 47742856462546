/*!
 *
 *  button.ts
 *
 */
class ButtonHover {
    constructor(elem) {
        elem.addEventListener('mouseenter', () => {
            elem.classList.remove('-mouseleave');
            elem.classList.add('-mouseenter');
        });
        elem.addEventListener('mouseleave', () => {
            elem.classList.remove('-mouseenter');
            elem.classList.add('-mouseleave');
        });
    }
}
;
export default function button(className) {
    const buttons = document.querySelectorAll(className);
    buttons.forEach((button) => {
        new ButtonHover(button);
    });
}
;
